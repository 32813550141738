import {observable} from "mobx";
import {
    CompanyType,
    CounterpartyDto,
    Department,
    IStatesMapping,
    OrderField,
    Organization,
    SalePrice,
    SalesChannel
} from "../Entities/Dtos";
import orderLookupStorage from "./OrderLookupStorage";
import {AvitoStatus} from "../Entities/Order/AvitoStatus";
import fieldsLookupStorage from "./FieldsLookupStorage";

export default class OrderIntegrationSettingsStore {
    @observable organization?: Organization;
    @observable salesChannel?: SalesChannel;
    @observable department?: Department;
    @observable numberField?: OrderField;
    @observable trackNumberField?: OrderField;
    @observable dispatchNumberField?: OrderField;
    @observable deliveryServiceNameField?: OrderField;
    @observable deliveryAddressField?: OrderField;
    @observable buyerFullNameField?: OrderField;
    @observable buyerPhoneNumberField?: OrderField;
    @observable group?: string;
    @observable companyType?: CompanyType;
    @observable counterpartyId?: string;
    @observable unpackBundles: boolean;
    @observable componentPriceForUnpackBundle?: SalePrice;
    @observable reservePositions: boolean;
    @observable removeCommissionFromPrice: boolean;
    @observable removeDiscountFromPrice: boolean;
    @observable statesMapping: IStatesMapping;
    @observable needCreateCounterparty: boolean;
    @observable useMsNumeration: boolean;
    @observable vat: number;

    @observable hasChanges: boolean = false;
    onChange: () => void;
    
    static defaultStatesMapping: IStatesMapping = {
        Canceled: undefined,
        Closed: undefined,
        Delivered: undefined,
        InDispute: undefined,
        InTransit: undefined,
        OnConfirmation: undefined,
        OnReturn: undefined,
        ReadyToShip: undefined
    }
    
    constructor(onChange: () => void, 
                isNew: boolean, 
                organization?: Organization, 
                salesChannel?: SalesChannel, 
                department?: Department, 
                group?: string,
                companyType?: CompanyType,
                counterparty?: CounterpartyDto,
                numberField?: OrderField,
                trackNumberField?: OrderField,
                dispatchNumberField?: OrderField,
                deliveryServiceNameField?: OrderField,
                deliveryAddressField?: OrderField,
                buyerFullNameField?: OrderField,
                buyerPhoneNumberField?: OrderField,
                statesMapping?: IStatesMapping,
                componentPriceForUnpackBundle?: SalePrice, 
                reservePositions: boolean = false,
                removeCommissionFromPrice: boolean = false,
                removeDiscountFromPrice: boolean = false,
                vat: number = 0,) {
        this.organization = organization;
        this.salesChannel = salesChannel;
        this.department = department;
        this.group = group;
        this.companyType = companyType;
        this.counterpartyId = counterparty?.id;
        this.needCreateCounterparty = !counterparty;
        this.numberField = numberField;
        this.trackNumberField = trackNumberField;
        this.dispatchNumberField = dispatchNumberField;
        this.deliveryServiceNameField = deliveryServiceNameField;
        this.deliveryAddressField = deliveryAddressField;
        this.buyerFullNameField = buyerFullNameField;
        this.buyerPhoneNumberField = buyerPhoneNumberField;
        this.useMsNumeration = numberField !== undefined;
        this.statesMapping = statesMapping || OrderIntegrationSettingsStore.defaultStatesMapping;
        this.unpackBundles = componentPriceForUnpackBundle !== undefined;
        this.componentPriceForUnpackBundle = componentPriceForUnpackBundle;
        this.reservePositions = reservePositions;
        this.removeCommissionFromPrice = removeCommissionFromPrice;
        this.removeDiscountFromPrice = removeDiscountFromPrice;
        this.vat = vat;
        this.onChange = onChange;
        this.hasChanges = isNew;
    }
    
    change() {
        this.hasChanges = true;
        this.onChange();
    }
    
    get canSave(): boolean {
        if (!this.needCreateCounterparty && !this.counterpartyId) {
            return false;
        }

        if (this.useMsNumeration && !this.numberField) {
            return false;
        }
        
        if (this.needCreateCounterparty && !this.companyType) {
            return false;
        }
        
        return this.organization !== undefined;
    }
    
    serialize(): any {
        if (!this.canSave) return;
        return {
            organizationId: this.organization!.id,
            salesChannelId: this.salesChannel?.id,
            departmentId: this.department?.id,
            group: this.group,
            companyTypeId: this.companyType?.id,
            counterpartyId: this.counterpartyId === "" ? null : this.counterpartyId,
            numberFieldId: this.numberField?.id,
            trackNumberFieldId: this.trackNumberField?.id,
            dispatchNumberFieldId: this.dispatchNumberField?.id,
            deliveryServiceNameFieldId: this.deliveryServiceNameField?.id,
            deliveryAddressFieldId: this.deliveryAddressField?.id,
            buyerFullNameFieldId: this.buyerFullNameField?.id,
            buyerPhoneNumberFieldId: this.buyerPhoneNumberField?.id,
            statesMapping: this.statesMapping,
            componentPriceForUnpackBundleId: this.componentPriceForUnpackBundle?.id,
            reservePositions: this.reservePositions,
            removeCommissionFromPrice: this.removeCommissionFromPrice,
            removeDiscountFromPrice: this.removeDiscountFromPrice,
            vat: this.vat,
        };
    }

    setOrganization(id: string) {
        this.organization = orderLookupStorage.organizations.find(x => x.id === id);
        this.change();
    }

    setSalesChannel(id: string) {
        this.salesChannel = orderLookupStorage.salesChannels.find(x => x.id === id);
        this.change();
    }

    setDepartment(id: string) {
        this.department = orderLookupStorage.departments.find(x => x.id === id);
        this.change();
    }
    
    setNumberField(id: string) {
        this.numberField = orderLookupStorage.orderFields.find(x => x.id === id);
        this.change();
    }

    setTrackNumberField(id: string) {
        this.trackNumberField = orderLookupStorage.orderFields.find(x => x.id === id);
        this.change();
    }

    setDispatchNumberField(id: string) {
        this.dispatchNumberField = orderLookupStorage.orderFields.find(x => x.id === id);
        this.change();
    }
    
    setDeliveryServiceNameField(id: string) {
        this.deliveryServiceNameField = orderLookupStorage.orderFields.find(x => x.id === id);
        this.change();
    }
    
    setDeliveryAddressField(id: string) {
        this.deliveryAddressField = orderLookupStorage.orderFields.find(x => x.id === id);
        this.change();
    }

    setBuyerFullNameFieldField(id: string) {
        this.buyerFullNameField = orderLookupStorage.orderFields.find(x => x.id === id);
        this.change();
    }

    setBuyerPhoneNumberFieldField(id: string) {
        this.buyerPhoneNumberField = orderLookupStorage.orderFields.find(x => x.id === id);
        this.change();
    }

    setUnpackBundles(value: boolean) {
        this.unpackBundles = value;
        if (!value) {
            this.componentPriceForUnpackBundle = undefined;
        }
        this.change();
    }
    
    setComponentPriceForUnpackBundle(id: string) {
        this.componentPriceForUnpackBundle = fieldsLookupStorage.prices.find(x => x.id === id);
        this.change();
    }

    setCompanyType(id: string) {
        this.companyType = orderLookupStorage.companyTypes.find(x => x.id === id);
        this.change();
    }

    setReservePositions(value: boolean) {
        this.reservePositions = value;
        this.change();
    }
    
    setGroup(value: string) {
        this.group = value;
        this.change();
    }
    
    setCounterpartyId(value: string) {
        this.counterpartyId = value;
        this.change();
    }

    setNeedCreateCounterparty(value: boolean) {
        this.needCreateCounterparty = value;
        if (value) {
            this.counterpartyId = undefined;
        }
        this.change();
    }

    setRemoveCommissionFromPrice(value: boolean) {
        this.removeCommissionFromPrice = value;
        this.change();
    }

    setRemoveDiscountFromPrice(value: boolean) {
        this.removeDiscountFromPrice = value;
        this.change();
    }

    setVat(value: number) {
        if (value < 0 || value > 100) return;
        this.vat = value;
        this.change();
    }
    
    setUseMsNumeration(value: boolean){
        this.useMsNumeration = value;
        if (!value) {
            this.numberField = undefined;
        }
        this.change();
    }

    setMoySkladStatusForAvitoStatus(status: AvitoStatus, id?: string) {
        const orderState = orderLookupStorage.orderStates.find(x => x.id === id);
        if(orderState) {
            this.statesMapping[status] = orderState;
        } else {
            this.statesMapping[status] = undefined;
        }
        this.change();
    }
}