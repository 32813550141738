import {observer} from 'mobx-react-lite'
import React from 'react'
import {Dropdown} from 'semantic-ui-react'
import skladStore from "../../../../../Stores/SkladStore";
import fieldsLookupStorage from "../../../../../Stores/FieldsLookupStorage";

interface IProps {
    onChecked: (value: string, custom: boolean) => void;
    fixHeight?: boolean;
}

function BasicFieldSelector(props: IProps) {
    return (
        <Dropdown
            icon='angle down'
            button
            className={props.fixHeight ? 'icon fix-height' : 'icon'}
            direction='right'
            style={{marginRight: '1px', color: '#2185d0'}}
        >
            <Dropdown.Menu>
                <Dropdown.Header content='Поля из МойСклад'/>
                <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                    {fieldsLookupStorage.baseFields.map((f) => (
                        <Dropdown.Item key={f.id} text={f.name} onClick={() => props.onChecked(f.name, false)}
                                       label={{color: 'green', empty: true, circular: true}}/>
                    ))}
                </Dropdown.Menu>
                <Dropdown.Header content='Цены продажи'/>
                <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                    {skladStore.bundleSalePriceId !== null && skladStore.bundleSalePriceId !== undefined &&
                        <Dropdown.Item key={skladStore.bundleSalePriceId}
                                       text={"Цена комплекта"}
                                       onClick={() => props.onChecked("Цена комплекта", false)}
                                       label={{color: 'blue', empty: true, circular: true}}
                        />
                    }
                    {fieldsLookupStorage.prices.map((f) => (
                        <Dropdown.Item key={f.id} text={f.name} onClick={() => props.onChecked(f.name, false)}
                                       label={{color: 'blue', empty: true, circular: true}}/>
                    ))}
                </Dropdown.Menu>
                <>
                    <Dropdown.Header content='Поля складов'/>
                    <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                        {fieldsLookupStorage.storeFields 
                            && fieldsLookupStorage.storeFields.length > 0 
                            && fieldsLookupStorage.storeFields.map((f) => (
                            <>
                                <Dropdown.Item key={f.id} text={f.name}
                                               onClick={() => props.onChecked(f.name, false)}
                                               label={{color: 'purple', empty: true, circular: true}}/>
                                {f.type === "customentity" &&
                                    <Dropdown.Item key={f.id + '1'} text={f.name + '.Описание'}
                                                   onClick={() => props.onChecked(f.name + '.Описание', false)}
                                                   label={{color: 'purple', empty: true, circular: true}}/>
                                }
                            </>
                        ))}
                        <Dropdown.Item key={'store_description'} text={'ОПИСАНИЕ СКЛАДА'}
                                       onClick={() => props.onChecked('ОПИСАНИЕ СКЛАДА', false)}
                                       label={{color: 'purple', empty: true, circular: true}}/>
                        <Dropdown.Item key={'store_price'} text={'ЦЕНА СКЛАДА'}
                                       onClick={() => props.onChecked('ЦЕНА СКЛАДА', false)}
                                       label={{color: 'purple', empty: true, circular: true}}/>
                        <Dropdown.Item key={'store_stock'} text={'Остаток'}
                                       onClick={() => props.onChecked('Остаток', false)}
                                       label={{color: 'purple', empty: true, circular: true}}/>
                    </Dropdown.Menu>
                </>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default observer(BasicFieldSelector);
