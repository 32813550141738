import React, {useEffect} from 'react';
import {InformationPanelItem} from './InformationPanelItem';
import UpdateButton from '../Profile/SkladSettings/UpdateButton/UpdateButton';
import Cron from './Cron';
import {observer} from 'mobx-react-lite';
import CheckboxWithTip from "./CheckboxWithTip";
import skladStore from "../../Stores/SkladStore";
import StoreSettings from "../Profile/SkladSettings/StoreSettings/StoreSettings";
import BundleSalePriceSelector from "../Profile/SkladSettings/BundleSalePriceSelector";
import FieldIdSelector from "../Shared/FieldIdSelector";
import fieldsLookupStorage from "../../Stores/FieldsLookupStorage";
import {Button, Header} from "semantic-ui-react";

function IntegrationSettings() {
    useEffect(() => {
        void skladStore.load();
    }, [])

    return (
        <InformationPanelItem header="Настройки интеграции" loading={skladStore.loading}>
            <>
                <Header as='h5' textAlign='center'>
                    {'Поле отвечающее за интеграцию'}
                </Header>
                <FieldIdSelector
                    value={skladStore.integrationField}
                    fields={fieldsLookupStorage.customFields.filter(x=> x.type === "boolean")} 
                    onChange={value=>{
                    skladStore.integrationField = value;
                    skladStore.hasChanges = true;
                }}/>
            </>
            <UpdateButton/>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <CheckboxWithTip
                    label='Игнорировать поле интеграции в комплектующих'
                    tip='При выгрузке комплектов, поле интеграции в комплектующих комплекта будет проигнорировано.'
                    checked={skladStore.ignoreIntegrationFieldInComponents}
                    onChange={(checked) => {
                        skladStore.ignoreIntegrationFieldInComponents = checked;
                        skladStore.hasChanges = true;
                    }}
                />
                <CheckboxWithTip
                    label='Разрешать дубли товаров'
                    tip='Разрешает создание более одного объявления из одного товара. Подробнее про данную опцию можно прочитать в документации.'
                    checked={skladStore.allowProductDuplications}
                    onChange={(checked) => {
                        skladStore.allowProductDuplications = checked;
                        skladStore.hasChanges = true;
                    }}
                />
                <CheckboxWithTip
                    label='Выгружать только комплекты'
                    tip='Товары выгружаться не будут. Если нужны только комплекты, данная опция ускорит выгрузку.'
                    checked={skladStore.onlyBundles}
                    onChange={(checked) => {
                        skladStore.onlyBundles = checked;
                        skladStore.hasChanges = true;
                    }}
                />
                <CheckboxWithTip
                    label='Обрезать Наименование объявлений до 50 символов'
                    tip='Обрезать последнее слово, если наименование объявления превышает 50 символов'
                    checked={skladStore.trimLastWordInAdTitleIfLong}
                    onChange={(checked) => {
                        skladStore.trimLastWordInAdTitleIfLong = checked;
                        skladStore.hasChanges = true;
                    }}
                />
                <CheckboxWithTip
                    label='Использовать математические операторы в шаблонах'
                    tip='Позволяет использовать математические операторы в шаблонах, например увеличивать цену для определнных складов или файлов'
                    checked={skladStore.useMaths}
                    onChange={(checked) => {
                        skladStore.useMaths = checked;
                        skladStore.hasChanges = true;
                    }}
                />
                <CheckboxWithTip
                    label='Рассчитывать цену комплекта'
                    tip='Цена комплекта будет формироваться из цен комплектующих'
                    checked={skladStore.enableBundlePriceCalculation}
                    onChange={(checked) => {
                        skladStore.enableBundlePriceCalculation = checked;
                        skladStore.hasChanges = true;
                    }}
                />
                {skladStore.enableBundlePriceCalculation &&
                    <>
                        <CheckboxWithTip
                            label='Не рассчитывать, если цена заполнена'
                            tip='Если в карточке комплекта указана цена, то рассчитываться она не будет'
                            checked={skladStore.notCalculateBundlePriceIfFilled}
                            onChange={(checked) => {
                                skladStore.notCalculateBundlePriceIfFilled = checked;
                                skladStore.hasChanges = true;
                            }}
                        />
                        <BundleSalePriceSelector />
                    </>
                }
                <CheckboxWithTip
                    label='Выгружать данные из каталога'
                    tip='В качестве источника данных о товарах будет использоваться Ваш каталог в МойСклад, то есть, вся информация о товарах, будет подгружаться из него. Обратите внимание, при выборе данной опции, будет не доступен функционал работы с остатками, резервом и товаром в ожидании.'
                    checked={!skladStore.needCheckStores}
                    onChange={(checked) => {
                        skladStore.needCheckStores = !checked;
                        skladStore.hasChanges = true;
                    }}
                />
                {!skladStore.needCheckStores &&
                    <>
                        <CheckboxWithTip
                            label={'Проверять остаток в доп поле'}
                            tip={'При выгрузке будет учитываться остаток в доп поле. Если он больше нуля, то товар попадет в выгрузку.'}
                            checked={skladStore.enableCustomStock}
                            onChange={checked => {
                                skladStore.enableCustomStock = checked;
                                skladStore.hasChanges = true;
                            }}
                        />
                        {skladStore.enableCustomStock &&
                            <>
                                <Header as='h5' textAlign='center'>
                                    {'Поле для проверки остатка'}
                                </Header>
                                <FieldIdSelector
                                    value={skladStore.customStockFieldId}
                                    fields={fieldsLookupStorage.customFields}
                                    onChange={value=>{
                                        skladStore.customStockFieldId = value;
                                        skladStore.hasChanges = true;
                                    }}/>
                            </>
                        }
                    </>
                }
                <CheckboxWithTip
                    label='Выгружать товары со складов'
                    tip='В качестве источника данных будут использоваться склады, которые Вы выберите. При этом будет доступен функционал работы с остатками, резервом и товаром в ожидании.'
                    checked={skladStore.needCheckStores}
                    onChange={(checked) => {
                        skladStore.needCheckStores = checked;
                        skladStore.hasChanges = true;
                    }}
                />
                {skladStore.needCheckStores &&
                    <CheckboxWithTip
                        label='Использовать ускоренную проверку остатков'
                        tip='Рекомендуемый способ проверки остатков'
                        checked={skladStore.fastStockCheck}
                        onChange={(checked) => {
                            skladStore.fastStockCheck = checked;
                            skladStore.hasChanges = true;
                        }}
                    />
                }
            </div>
            {skladStore.needCheckStores && <StoreSettings/>}
            <Cron/>
            <Button fluid color='teal'
                    onClick={() => skladStore.save()}
                    disabled={!skladStore.hasChanges}
                    loading={skladStore.isSaving}>Сохранить настройки</Button>
        </InformationPanelItem>
    );
}

export default observer(IntegrationSettings);
