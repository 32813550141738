import {observer} from 'mobx-react-lite';
import React from 'react';
import {Dropdown, Grid, Header} from 'semantic-ui-react';
import CreateFeedButton from '../Profile/SkladSettings/CreateFeedButton/CreateFeedButton';
import skladStore from "../../Stores/SkladStore";
import integrationIntervalsLookupStore from "../../Stores/IntegrationIntervalsLookupStore";
import FieldIdSelector from "../Shared/FieldIdSelector";

function Cron() {
    return (
        <>
            <Header as='h5' textAlign='center'>
                Периодичность выгрузки
            </Header>
            <Grid centered style={{marginBottom: '15px'}}>
                <Grid.Row verticalAlign={'middle'}>
                    <div>Выгружать файлы &nbsp;
                        <FieldIdSelector
                            value={skladStore.intervalId}
                            fields={integrationIntervalsLookupStore.intervals}
                            onChange={value => {
                                skladStore.intervalId = value ?? '';
                                skladStore.hasChanges = true;
                            }}
                            fluid={false}
                            placeholder={'Выберите интервал выгрузки'}
                        />
                        {skladStore.selectedInterval && skladStore.selectedInterval.showTimePicker && <>&nbsp; в <input
                            style={{marginLeft: '5px'}}
                            type="time"
                            value={skladStore.period}
                            onChange={(e) => {
                                skladStore.period = e.target.value;
                                skladStore.hasChanges = true;
                            }}/>
                            &nbsp; по МСК &nbsp;
                        </>
                        }
                    </div>

                </Grid.Row>
                <Grid.Row verticalAlign={'middle'} style={{paddingTop: '0px'}}>
                    <CreateFeedButton/>
                </Grid.Row>
            </Grid>
        </>
    );
}

export default observer(Cron);