import IHasIdAndName, {ICustomField} from "../Entities/IHasIdAndName";
import {observable} from "mobx";
import api from "../Services/Api";

class FieldsLookupStorage {
    @observable baseFields: IHasIdAndName[] = []
    @observable customFields: ICustomField[] = []
    @observable prices: IHasIdAndName[] = []
    @observable storeFields: ICustomField[] = []
    @observable unloadFields: IHasIdAndName[] = []
    @observable characteristics: IHasIdAndName[] = []
    @observable hasCustomFields: boolean = false;

    async load() {
        let result = await api.get('baseField/list');
        if (result.success) {
            this.baseFields = result.data as IHasIdAndName[];
        }

        result = await api.get('customField/list');
        if (result.success) {
            this.customFields = result.data as ICustomField[];
        }

        result = await api.get('price/list');
        if (result.success) {
            this.prices = result.data as IHasIdAndName[];
        }

        result = await api.get('store/field/list');
        if (result.success) {
            this.storeFields = result.data as ICustomField[];
        }

        result = await api.get('unload/field/list');
        if (result.success) {
            this.unloadFields = result.data as IHasIdAndName[];
        }

        result = await api.get('characteristic/list');
        if (result.success) {
            this.characteristics = result.data as IHasIdAndName[];
        }

        this.hasCustomFields = (this.customFields && this.customFields.length > 0)
            || (this.unloadFields && this.unloadFields.length > 0);
    }
}

const fieldsLookupStorage = new FieldsLookupStorage();
export default fieldsLookupStorage;