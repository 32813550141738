import { Dropdown, Header } from "semantic-ui-react";
import React from 'react';
import { observer } from "mobx-react-lite";
import skladStore from "../../../Stores/SkladStore";
import FieldIdSelector from "../../Shared/FieldIdSelector";
import fieldsLookupStorage from "../../../Stores/FieldsLookupStorage";

function BundleSalePriceSelector() {
    return (
        <>
            <Header as='h5' textAlign='center' style={{marginTop: '10px'}}>
                Цена для рассчета комплекта
            </Header>
            <FieldIdSelector fields={fieldsLookupStorage.prices} 
                             value={skladStore.bundleSalePriceId}
                             onChange={value => {
                skladStore.bundleSalePriceId = value;
                skladStore.hasChanges = true;
            }}/>
        </>
    );
}

export default observer(BundleSalePriceSelector);