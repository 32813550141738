import {observer} from 'mobx-react-lite';
import React from 'react'
import {Dropdown} from 'semantic-ui-react'
import fieldsLookupStorage from "../../../../../Stores/FieldsLookupStorage";

interface IProps {
    onChecked: (value: string, custom: boolean) => void;
    onCheckedFileField: (value: string) => void;
    fixHeight?: boolean;
    showFileFields: boolean;
}

function CustomFieldSelector(props: IProps) {
    return (
        <Dropdown
            icon='angle down'
            button
            className={props.fixHeight ? 'icon fix-height' : 'icon'}
            direction='right'
            style={{color: '#db2828', marginRight: '1px'}}
        >
            <Dropdown.Menu>
                <Dropdown.Header content='Кастомные поля'/>
                <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                    {fieldsLookupStorage.customFields.map((f) => (
                        <React.Fragment key={f.id}>
                            <Dropdown.Item text={f.name} onClick={() => props.onChecked(f.name, true)}
                                           label={{color: 'red', empty: true, circular: true}}/>
                            {f.type === "customentity" &&
                                <Dropdown.Item key={'custom-description'} text={f.name + '.Описание'}
                                               onClick={() => props.onChecked(f.name + '.Описание', true)}
                                               label={{color: 'red', empty: true, circular: true}}/>
                            }
                        </React.Fragment>
                    ))}
                </Dropdown.Menu>
                {fieldsLookupStorage.characteristics && fieldsLookupStorage.characteristics.length > 0 &&
                    <>
                        <Dropdown.Header content='Характеристики модификаций'/>
                        <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                            {fieldsLookupStorage.characteristics.map((f) => (
                                <Dropdown.Item key={f.id} text={f.name} onClick={() => props.onChecked(f.name, true)}
                                               label={{color: 'blue', empty: true, circular: true}}/>
                            ))}
                        </Dropdown.Menu>
                    </>
                }
                {props.showFileFields && <>
                    <Dropdown.Header content='Поля файлов'/>
                    <Dropdown.Menu scrolling style={{maxHeight: '150px'}}>
                        {fieldsLookupStorage.unloadFields
                            && fieldsLookupStorage.unloadFields.length > 0
                            && fieldsLookupStorage.unloadFields.map((f) => (
                                <Dropdown.Item key={f.id} text={f.name} onClick={() => props.onCheckedFileField(f.name)}
                                               label={{color: 'orange', empty: true, circular: true}}/>
                            ))}
                        <Dropdown.Item key={'StockFileField'} text={'Остаток'}
                                       onClick={() => props.onCheckedFileField('Остаток')}
                                       label={{color: 'orange', empty: true, circular: true}}/>
                        <Dropdown.Item key={'FileDescriptionField'} text={'ОПИСАНИЕ ДЛЯ ФАЙЛА'}
                                       onClick={() => props.onCheckedFileField('ОПИСАНИЕ ДЛЯ ФАЙЛА')}
                                       label={{color: 'orange', empty: true, circular: true}}/>
                        <Dropdown.Item key={'FilePriceField'} text={'ЦЕНА ДЛЯ ФАЙЛА'}
                                       onClick={() => props.onCheckedFileField('ЦЕНА ДЛЯ ФАЙЛА')}
                                       label={{color: 'orange', empty: true, circular: true}}/>
                    </Dropdown.Menu>
                </>}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default observer(CustomFieldSelector);
