import api from "../Services/Api";
import {IntegrationIntervalDto} from "../Entities/Dtos";
import {observable} from "mobx";

class IntegrationIntervalsLookupStore {
    @observable intervals: IntegrationIntervalDto[] = []
    async load() {
        const result = await api.get('integrationInterval/list');
        if (result.success) {
            this.intervals = result.data;
        }
    }
}

const integrationIntervalsLookupStore = new IntegrationIntervalsLookupStore();
export default integrationIntervalsLookupStore;